import { createContext as K } from "react";
import { N as M, u as R } from "./index.esm--5Btn0kW.js";
const tr = K(void 0);
function j(p) {
  throw new Error('Could not dynamically require "' + p + '". Please configure the dynamicRequireTargets or/and ignoreDynamicRequires option of @rollup/plugin-commonjs appropriately for this require call to work.');
}
var W = { exports: {} };
const J = {}, Q = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  default: J
}, Symbol.toStringTag, { value: "Module" })), X = /* @__PURE__ */ M(Q);
var P;
function F() {
  return P || (P = 1, function(p, x) {
    (function(v, s) {
      p.exports = s();
    })(R, function() {
      var v = v || function(s, C) {
        var c;
        if (typeof window < "u" && window.crypto && (c = window.crypto), typeof self < "u" && self.crypto && (c = self.crypto), typeof globalThis < "u" && globalThis.crypto && (c = globalThis.crypto), !c && typeof window < "u" && window.msCrypto && (c = window.msCrypto), !c && typeof R < "u" && R.crypto && (c = R.crypto), !c && typeof j == "function")
          try {
            c = X;
          } catch {
          }
        var b = function() {
          if (c) {
            if (typeof c.getRandomValues == "function")
              try {
                return c.getRandomValues(new Uint32Array(1))[0];
              } catch {
              }
            if (typeof c.randomBytes == "function")
              try {
                return c.randomBytes(4).readInt32LE();
              } catch {
              }
          }
          throw new Error("Native crypto module could not be used to get secure random number.");
        }, S = Object.create || /* @__PURE__ */ function() {
          function r() {
          }
          return function(t) {
            var n;
            return r.prototype = t, n = new r(), r.prototype = null, n;
          };
        }(), g = {}, w = g.lib = {}, y = w.Base = /* @__PURE__ */ function() {
          return {
            /**
             * Creates a new object that inherits from this object.
             *
             * @param {Object} overrides Properties to copy into the new object.
             *
             * @return {Object} The new object.
             *
             * @static
             *
             * @example
             *
             *     var MyType = CryptoJS.lib.Base.extend({
             *         field: 'value',
             *
             *         method: function () {
             *         }
             *     });
             */
            extend: function(r) {
              var t = S(this);
              return r && t.mixIn(r), (!t.hasOwnProperty("init") || this.init === t.init) && (t.init = function() {
                t.$super.init.apply(this, arguments);
              }), t.init.prototype = t, t.$super = this, t;
            },
            /**
             * Extends this object and runs the init method.
             * Arguments to create() will be passed to init().
             *
             * @return {Object} The new object.
             *
             * @static
             *
             * @example
             *
             *     var instance = MyType.create();
             */
            create: function() {
              var r = this.extend();
              return r.init.apply(r, arguments), r;
            },
            /**
             * Initializes a newly created object.
             * Override this method to add some logic when your objects are created.
             *
             * @example
             *
             *     var MyType = CryptoJS.lib.Base.extend({
             *         init: function () {
             *             // ...
             *         }
             *     });
             */
            init: function() {
            },
            /**
             * Copies properties into this object.
             *
             * @param {Object} properties The properties to mix in.
             *
             * @example
             *
             *     MyType.mixIn({
             *         field: 'value'
             *     });
             */
            mixIn: function(r) {
              for (var t in r)
                r.hasOwnProperty(t) && (this[t] = r[t]);
              r.hasOwnProperty("toString") && (this.toString = r.toString);
            },
            /**
             * Creates a copy of this object.
             *
             * @return {Object} The clone.
             *
             * @example
             *
             *     var clone = instance.clone();
             */
            clone: function() {
              return this.init.prototype.extend(this);
            }
          };
        }(), f = w.WordArray = y.extend({
          /**
           * Initializes a newly created word array.
           *
           * @param {Array} words (Optional) An array of 32-bit words.
           * @param {number} sigBytes (Optional) The number of significant bytes in the words.
           *
           * @example
           *
           *     var wordArray = CryptoJS.lib.WordArray.create();
           *     var wordArray = CryptoJS.lib.WordArray.create([0x00010203, 0x04050607]);
           *     var wordArray = CryptoJS.lib.WordArray.create([0x00010203, 0x04050607], 6);
           */
          init: function(r, t) {
            r = this.words = r || [], t != C ? this.sigBytes = t : this.sigBytes = r.length * 4;
          },
          /**
           * Converts this word array to a string.
           *
           * @param {Encoder} encoder (Optional) The encoding strategy to use. Default: CryptoJS.enc.Hex
           *
           * @return {string} The stringified word array.
           *
           * @example
           *
           *     var string = wordArray + '';
           *     var string = wordArray.toString();
           *     var string = wordArray.toString(CryptoJS.enc.Utf8);
           */
          toString: function(r) {
            return (r || o).stringify(this);
          },
          /**
           * Concatenates a word array to this word array.
           *
           * @param {WordArray} wordArray The word array to append.
           *
           * @return {WordArray} This word array.
           *
           * @example
           *
           *     wordArray1.concat(wordArray2);
           */
          concat: function(r) {
            var t = this.words, n = r.words, a = this.sigBytes, h = r.sigBytes;
            if (this.clamp(), a % 4)
              for (var d = 0; d < h; d++) {
                var _ = n[d >>> 2] >>> 24 - d % 4 * 8 & 255;
                t[a + d >>> 2] |= _ << 24 - (a + d) % 4 * 8;
              }
            else
              for (var B = 0; B < h; B += 4)
                t[a + B >>> 2] = n[B >>> 2];
            return this.sigBytes += h, this;
          },
          /**
           * Removes insignificant bits.
           *
           * @example
           *
           *     wordArray.clamp();
           */
          clamp: function() {
            var r = this.words, t = this.sigBytes;
            r[t >>> 2] &= 4294967295 << 32 - t % 4 * 8, r.length = s.ceil(t / 4);
          },
          /**
           * Creates a copy of this word array.
           *
           * @return {WordArray} The clone.
           *
           * @example
           *
           *     var clone = wordArray.clone();
           */
          clone: function() {
            var r = y.clone.call(this);
            return r.words = this.words.slice(0), r;
          },
          /**
           * Creates a word array filled with random bytes.
           *
           * @param {number} nBytes The number of random bytes to generate.
           *
           * @return {WordArray} The random word array.
           *
           * @static
           *
           * @example
           *
           *     var wordArray = CryptoJS.lib.WordArray.random(16);
           */
          random: function(r) {
            for (var t = [], n = 0; n < r; n += 4)
              t.push(b());
            return new f.init(t, r);
          }
        }), u = g.enc = {}, o = u.Hex = {
          /**
           * Converts a word array to a hex string.
           *
           * @param {WordArray} wordArray The word array.
           *
           * @return {string} The hex string.
           *
           * @static
           *
           * @example
           *
           *     var hexString = CryptoJS.enc.Hex.stringify(wordArray);
           */
          stringify: function(r) {
            for (var t = r.words, n = r.sigBytes, a = [], h = 0; h < n; h++) {
              var d = t[h >>> 2] >>> 24 - h % 4 * 8 & 255;
              a.push((d >>> 4).toString(16)), a.push((d & 15).toString(16));
            }
            return a.join("");
          },
          /**
           * Converts a hex string to a word array.
           *
           * @param {string} hexStr The hex string.
           *
           * @return {WordArray} The word array.
           *
           * @static
           *
           * @example
           *
           *     var wordArray = CryptoJS.enc.Hex.parse(hexString);
           */
          parse: function(r) {
            for (var t = r.length, n = [], a = 0; a < t; a += 2)
              n[a >>> 3] |= parseInt(r.substr(a, 2), 16) << 24 - a % 8 * 4;
            return new f.init(n, t / 2);
          }
        }, l = u.Latin1 = {
          /**
           * Converts a word array to a Latin1 string.
           *
           * @param {WordArray} wordArray The word array.
           *
           * @return {string} The Latin1 string.
           *
           * @static
           *
           * @example
           *
           *     var latin1String = CryptoJS.enc.Latin1.stringify(wordArray);
           */
          stringify: function(r) {
            for (var t = r.words, n = r.sigBytes, a = [], h = 0; h < n; h++) {
              var d = t[h >>> 2] >>> 24 - h % 4 * 8 & 255;
              a.push(String.fromCharCode(d));
            }
            return a.join("");
          },
          /**
           * Converts a Latin1 string to a word array.
           *
           * @param {string} latin1Str The Latin1 string.
           *
           * @return {WordArray} The word array.
           *
           * @static
           *
           * @example
           *
           *     var wordArray = CryptoJS.enc.Latin1.parse(latin1String);
           */
          parse: function(r) {
            for (var t = r.length, n = [], a = 0; a < t; a++)
              n[a >>> 2] |= (r.charCodeAt(a) & 255) << 24 - a % 4 * 8;
            return new f.init(n, t);
          }
        }, e = u.Utf8 = {
          /**
           * Converts a word array to a UTF-8 string.
           *
           * @param {WordArray} wordArray The word array.
           *
           * @return {string} The UTF-8 string.
           *
           * @static
           *
           * @example
           *
           *     var utf8String = CryptoJS.enc.Utf8.stringify(wordArray);
           */
          stringify: function(r) {
            try {
              return decodeURIComponent(escape(l.stringify(r)));
            } catch {
              throw new Error("Malformed UTF-8 data");
            }
          },
          /**
           * Converts a UTF-8 string to a word array.
           *
           * @param {string} utf8Str The UTF-8 string.
           *
           * @return {WordArray} The word array.
           *
           * @static
           *
           * @example
           *
           *     var wordArray = CryptoJS.enc.Utf8.parse(utf8String);
           */
          parse: function(r) {
            return l.parse(unescape(encodeURIComponent(r)));
          }
        }, i = w.BufferedBlockAlgorithm = y.extend({
          /**
           * Resets this block algorithm's data buffer to its initial state.
           *
           * @example
           *
           *     bufferedBlockAlgorithm.reset();
           */
          reset: function() {
            this._data = new f.init(), this._nDataBytes = 0;
          },
          /**
           * Adds new data to this block algorithm's buffer.
           *
           * @param {WordArray|string} data The data to append. Strings are converted to a WordArray using UTF-8.
           *
           * @example
           *
           *     bufferedBlockAlgorithm._append('data');
           *     bufferedBlockAlgorithm._append(wordArray);
           */
          _append: function(r) {
            typeof r == "string" && (r = e.parse(r)), this._data.concat(r), this._nDataBytes += r.sigBytes;
          },
          /**
           * Processes available data blocks.
           *
           * This method invokes _doProcessBlock(offset), which must be implemented by a concrete subtype.
           *
           * @param {boolean} doFlush Whether all blocks and partial blocks should be processed.
           *
           * @return {WordArray} The processed data.
           *
           * @example
           *
           *     var processedData = bufferedBlockAlgorithm._process();
           *     var processedData = bufferedBlockAlgorithm._process(!!'flush');
           */
          _process: function(r) {
            var t, n = this._data, a = n.words, h = n.sigBytes, d = this.blockSize, _ = d * 4, B = h / _;
            r ? B = s.ceil(B) : B = s.max((B | 0) - this._minBufferSize, 0);
            var H = B * d, A = s.min(H * 4, h);
            if (H) {
              for (var k = 0; k < H; k += d)
                this._doProcessBlock(a, k);
              t = a.splice(0, H), n.sigBytes -= A;
            }
            return new f.init(t, A);
          },
          /**
           * Creates a copy of this object.
           *
           * @return {Object} The clone.
           *
           * @example
           *
           *     var clone = bufferedBlockAlgorithm.clone();
           */
          clone: function() {
            var r = y.clone.call(this);
            return r._data = this._data.clone(), r;
          },
          _minBufferSize: 0
        });
        w.Hasher = i.extend({
          /**
           * Configuration options.
           */
          cfg: y.extend(),
          /**
           * Initializes a newly created hasher.
           *
           * @param {Object} cfg (Optional) The configuration options to use for this hash computation.
           *
           * @example
           *
           *     var hasher = CryptoJS.algo.SHA256.create();
           */
          init: function(r) {
            this.cfg = this.cfg.extend(r), this.reset();
          },
          /**
           * Resets this hasher to its initial state.
           *
           * @example
           *
           *     hasher.reset();
           */
          reset: function() {
            i.reset.call(this), this._doReset();
          },
          /**
           * Updates this hasher with a message.
           *
           * @param {WordArray|string} messageUpdate The message to append.
           *
           * @return {Hasher} This hasher.
           *
           * @example
           *
           *     hasher.update('message');
           *     hasher.update(wordArray);
           */
          update: function(r) {
            return this._append(r), this._process(), this;
          },
          /**
           * Finalizes the hash computation.
           * Note that the finalize operation is effectively a destructive, read-once operation.
           *
           * @param {WordArray|string} messageUpdate (Optional) A final message update.
           *
           * @return {WordArray} The hash.
           *
           * @example
           *
           *     var hash = hasher.finalize();
           *     var hash = hasher.finalize('message');
           *     var hash = hasher.finalize(wordArray);
           */
          finalize: function(r) {
            r && this._append(r);
            var t = this._doFinalize();
            return t;
          },
          blockSize: 16,
          /**
           * Creates a shortcut function to a hasher's object interface.
           *
           * @param {Hasher} hasher The hasher to create a helper for.
           *
           * @return {Function} The shortcut function.
           *
           * @static
           *
           * @example
           *
           *     var SHA256 = CryptoJS.lib.Hasher._createHelper(CryptoJS.algo.SHA256);
           */
          _createHelper: function(r) {
            return function(t, n) {
              return new r.init(n).finalize(t);
            };
          },
          /**
           * Creates a shortcut function to the HMAC's object interface.
           *
           * @param {Hasher} hasher The hasher to use in this HMAC helper.
           *
           * @return {Function} The shortcut function.
           *
           * @static
           *
           * @example
           *
           *     var HmacSHA256 = CryptoJS.lib.Hasher._createHmacHelper(CryptoJS.algo.SHA256);
           */
          _createHmacHelper: function(r) {
            return function(t, n) {
              return new m.HMAC.init(r, n).finalize(t);
            };
          }
        });
        var m = g.algo = {};
        return g;
      }(Math);
      return v;
    });
  }(W)), W.exports;
}
var z = { exports: {} }, T;
function er() {
  return T || (T = 1, function(p, x) {
    (function(v, s) {
      p.exports = s(F());
    })(R, function(v) {
      return function(s) {
        var C = v, c = C.lib, b = c.WordArray, S = c.Hasher, g = C.algo, w = [], y = [];
        (function() {
          function o(m) {
            for (var r = s.sqrt(m), t = 2; t <= r; t++)
              if (!(m % t))
                return !1;
            return !0;
          }
          function l(m) {
            return (m - (m | 0)) * 4294967296 | 0;
          }
          for (var e = 2, i = 0; i < 64; )
            o(e) && (i < 8 && (w[i] = l(s.pow(e, 1 / 2))), y[i] = l(s.pow(e, 1 / 3)), i++), e++;
        })();
        var f = [], u = g.SHA256 = S.extend({
          _doReset: function() {
            this._hash = new b.init(w.slice(0));
          },
          _doProcessBlock: function(o, l) {
            for (var e = this._hash.words, i = e[0], m = e[1], r = e[2], t = e[3], n = e[4], a = e[5], h = e[6], d = e[7], _ = 0; _ < 64; _++) {
              if (_ < 16)
                f[_] = o[l + _] | 0;
              else {
                var B = f[_ - 15], H = (B << 25 | B >>> 7) ^ (B << 14 | B >>> 18) ^ B >>> 3, A = f[_ - 2], k = (A << 15 | A >>> 17) ^ (A << 13 | A >>> 19) ^ A >>> 10;
                f[_] = H + f[_ - 7] + k + f[_ - 16];
              }
              var D = n & a ^ ~n & h, $ = i & m ^ i & r ^ m & r, N = (i << 30 | i >>> 2) ^ (i << 19 | i >>> 13) ^ (i << 10 | i >>> 22), V = (n << 26 | n >>> 6) ^ (n << 21 | n >>> 11) ^ (n << 7 | n >>> 25), O = d + V + D + y[_] + f[_], G = N + $;
              d = h, h = a, a = n, n = t + O | 0, t = r, r = m, m = i, i = O + G | 0;
            }
            e[0] = e[0] + i | 0, e[1] = e[1] + m | 0, e[2] = e[2] + r | 0, e[3] = e[3] + t | 0, e[4] = e[4] + n | 0, e[5] = e[5] + a | 0, e[6] = e[6] + h | 0, e[7] = e[7] + d | 0;
          },
          _doFinalize: function() {
            var o = this._data, l = o.words, e = this._nDataBytes * 8, i = o.sigBytes * 8;
            return l[i >>> 5] |= 128 << 24 - i % 32, l[(i + 64 >>> 9 << 4) + 14] = s.floor(e / 4294967296), l[(i + 64 >>> 9 << 4) + 15] = e, o.sigBytes = l.length * 4, this._process(), this._hash;
          },
          clone: function() {
            var o = S.clone.call(this);
            return o._hash = this._hash.clone(), o;
          }
        });
        C.SHA256 = S._createHelper(u), C.HmacSHA256 = S._createHmacHelper(u);
      }(Math), v.SHA256;
    });
  }(z)), z.exports;
}
var E = { exports: {} }, L;
function nr() {
  return L || (L = 1, function(p, x) {
    (function(v, s) {
      p.exports = s(F());
    })(R, function(v) {
      return function() {
        var s = v, C = s.lib, c = C.WordArray, b = s.enc;
        b.Base64 = {
          /**
           * Converts a word array to a Base64 string.
           *
           * @param {WordArray} wordArray The word array.
           *
           * @return {string} The Base64 string.
           *
           * @static
           *
           * @example
           *
           *     var base64String = CryptoJS.enc.Base64.stringify(wordArray);
           */
          stringify: function(g) {
            var w = g.words, y = g.sigBytes, f = this._map;
            g.clamp();
            for (var u = [], o = 0; o < y; o += 3)
              for (var l = w[o >>> 2] >>> 24 - o % 4 * 8 & 255, e = w[o + 1 >>> 2] >>> 24 - (o + 1) % 4 * 8 & 255, i = w[o + 2 >>> 2] >>> 24 - (o + 2) % 4 * 8 & 255, m = l << 16 | e << 8 | i, r = 0; r < 4 && o + r * 0.75 < y; r++)
                u.push(f.charAt(m >>> 6 * (3 - r) & 63));
            var t = f.charAt(64);
            if (t)
              for (; u.length % 4; )
                u.push(t);
            return u.join("");
          },
          /**
           * Converts a Base64 string to a word array.
           *
           * @param {string} base64Str The Base64 string.
           *
           * @return {WordArray} The word array.
           *
           * @static
           *
           * @example
           *
           *     var wordArray = CryptoJS.enc.Base64.parse(base64String);
           */
          parse: function(g) {
            var w = g.length, y = this._map, f = this._reverseMap;
            if (!f) {
              f = this._reverseMap = [];
              for (var u = 0; u < y.length; u++)
                f[y.charCodeAt(u)] = u;
            }
            var o = y.charAt(64);
            if (o) {
              var l = g.indexOf(o);
              l !== -1 && (w = l);
            }
            return S(g, w, f);
          },
          _map: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/="
        };
        function S(g, w, y) {
          for (var f = [], u = 0, o = 0; o < w; o++)
            if (o % 4) {
              var l = y[g.charCodeAt(o - 1)] << o % 4 * 2, e = y[g.charCodeAt(o)] >>> 6 - o % 4 * 2, i = l | e;
              f[u >>> 2] |= i << 24 - u % 4 * 8, u++;
            }
          return c.create(f, u);
        }
      }(), v.enc.Base64;
    });
  }(E)), E.exports;
}
function I(p) {
  this.message = p;
}
I.prototype = new Error(), I.prototype.name = "InvalidCharacterError";
var U = typeof window < "u" && window.atob && window.atob.bind(window) || function(p) {
  var x = String(p).replace(/=+$/, "");
  if (x.length % 4 == 1) throw new I("'atob' failed: The string to be decoded is not correctly encoded.");
  for (var v, s, C = 0, c = 0, b = ""; s = x.charAt(c++); ~s && (v = C % 4 ? 64 * v + s : s, C++ % 4) ? b += String.fromCharCode(255 & v >> (-2 * C & 6)) : 0) s = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=".indexOf(s);
  return b;
};
function Y(p) {
  var x = p.replace(/-/g, "+").replace(/_/g, "/");
  switch (x.length % 4) {
    case 0:
      break;
    case 2:
      x += "==";
      break;
    case 3:
      x += "=";
      break;
    default:
      throw "Illegal base64url string!";
  }
  try {
    return function(v) {
      return decodeURIComponent(U(v).replace(/(.)/g, function(s, C) {
        var c = C.charCodeAt(0).toString(16).toUpperCase();
        return c.length < 2 && (c = "0" + c), "%" + c;
      }));
    }(x);
  } catch {
    return U(x);
  }
}
function q(p) {
  this.message = p;
}
function or(p, x) {
  if (typeof p != "string") throw new q("Invalid token specified");
  var v = (x = x || {}).header === !0 ? 0 : 1;
  try {
    return JSON.parse(Y(p.split(".")[v]));
  } catch (s) {
    throw new q("Invalid token specified: " + s.message);
  }
}
q.prototype = new Error(), q.prototype.name = "InvalidTokenError";
export {
  tr as A,
  er as a,
  nr as b,
  or as o,
  F as r
};
